//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import L from 'leaflet';
import { OpenStreetMapProvider } from 'leaflet-geosearch';
export default {
    props: {
        value: {
            type: Object
        }
    },
    name: 'app-trip-gathering-location',
    data(){
        return{
            location: '',
            locationResults: [],
            map: null,
            marker: null,
            popupShow: false,
            loadingLocation: false
        }
    },
    methods: {
        i18n(key, args) {
            return this.$t(key, args);
        },
        async searchLocation(){
            this.popupShow = true
            const provider = new OpenStreetMapProvider();  
            this.locationResults = await provider.search({ query: this.location });
        },
        filterFn (val, update) {
            if (val === '') {
                update(() => {
                    // options.value = stringOptions
                    // here you have access to "ref" which
                    // is the Vue reference of the QSelect
                })
                return
            }
            update(async () => {
                this.loadingLocation = true
                const provider = new OpenStreetMapProvider();  
                this.locationResults = await provider.search({ query: val });
                this.loadingLocation = false
            })
        },
        changeMapMarker(item){
            var latlng = L.latLng(item.raw.lat, item.raw.lon); 
            this.marker.setLatLng(latlng)
            this.map.panTo({lon: item.raw.lon, lat: item.raw.lat});
            this.value.location = {
                longitude: parseFloat(item.raw.lon),
                latitude: parseFloat(item.raw.lat)
            }
        },
        onMarkerDrag(e) {
            this.value.location = {
                latitude: parseFloat(e.target._latlng.lat),
                longitude: parseFloat(e.target._latlng.lng)
            }
        },
    },
    mounted(){
        var greenIcon = L.icon({
            iconUrl: '/images/map/trip-marker.svg',
            // shadowUrl: '/images/map/trip-marker.svg',
            iconSize:     [38, 95], // size of the icon
            shadowSize:   [50, 64], // size of the shadow
            iconAnchor:   [22, 94], // point of the icon which will correspond to marker's location
            shadowAnchor: [4, 62],  // the same for the shadow
            popupAnchor:  [-3, -76] // point from which the popup should open relative to the iconAnchor
        });

        this.map = L.map('map').setView([21.485811, 39.19250479999999], 13);
        L.tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {
            attribution: '&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
        }).addTo(this.map);


        this.marker = L.marker([21.485811, 39.19250479999999], 
            {
                icon: greenIcon,
                draggable: true
            }).addTo(this.map)
        this.marker.on('dragend',this.onMarkerDrag)
    }
}
