//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapActions, mapGetters } from 'vuex';
import { getLanguageCode } from '@/i18n';
import moment from 'moment';
let timeStamp = Date.now()
let formattedDate =  moment(timeStamp).format('DD-MM-YYYY');
import { TripModel } from '@/modules/trip/trip-model';
import { FormSchema } from '@/shared/form/form-schema';
import TripGatheringLocation from '@/modules/trip/components/trip-gathering-location.vue'

const { fields } = TripModel;
const formSchema = new FormSchema([
    fields.name,
    fields.companyId,
    fields.type,
    fields.planId,
    fields.guidesId,
    fields.capacity,
    fields.price,
    fields.tripTime,
    fields.location,
    fields.options,
    fields.status,  
    fields.ownerType,
    fields.startCityId,
    fields.paymentMethod
]);
export default {
    data(){
        return{
            language: getLanguageCode(),
            rules: formSchema.rules(),
            planName: '',
            planId: '',
            plansOptions:[],
            guidesId: [],
            
                        
            guidesOptions:[],
            popupShow: false,
            findLoading: false,
            isEditing: false,
            tripCaptain: '',
            
            minCapacity:null,
            // date: moment(Date.now()).format('MMMM Do YYYY, h:mm:ss a'),
            date: moment(Date.now()).format('DD-MM-YYYY'),
            data: {
                start_date : formattedDate
            },
            currentTime:  new Date().getTime(),
            location: null,
            locationModal: false,
            addFeature: 0,
            success: false,
            model: null,
            detailsEn: '',

            types: [
                {
                    value: 'private',
                    label : this.i18n('entities.trip.form.private')
                },
                {
                    value: 'group',
                    label : this.i18n('entities.trip.form.group')

                }, 
                {
                    value: 'both',
                    label : this.i18n('entities.trip.form.both')
                   
                }
                
            ],
            paymentMethods: [
                 {
                    value: 'credit',
                    label : this.i18n('entities.trip.form.credit')
                },
                {
                    value: 'cash',
                    label : this.i18n('entities.trip.form.cash')

                }, 
                {
                    value: 'both',
                    label : this.i18n('entities.trip.form.both')
                   
                }],
            citiesOptions: [],

            tripTime: '',
            tripDate: '',
            time: '',
            map: null,
            searchControl: null,
            marker: null,
            locationResults: null

        }
    },
    components: {
        [TripGatheringLocation.name]: TripGatheringLocation,

    },
    computed:{
        ...mapGetters({
            record: 'trip/form/record',
            saveLoading: 'trip/form/saveLoading',
            plansRows: 'plan/list/rows',
            guidesRows: 'tourGuide/list/rows',
            citiesRows: 'city/list/rows',
            currentUser: 'auth/currentUser',
            loadingTourGuides: 'tourGuide/list/loading',
            loadingPlans: 'plan/list/loading',
            loadingCities: 'city/list/loading'
        }),
        fields() {
            return fields;
        },
        guideNames(){
            var arr =[]
            this.guidesOptions.forEach(element => {
                if(this.guidesId.includes(element.value)){
                    arr.push(element.label)
                }
                
            });
            return arr
        },
        tripCaptainId(){
            var arr = this.guidesOptions.filter(el => {
            return el.label == this.tripCaptain });
            return arr[0].value
        },
        cities() {
            return this.citiesRows.map(item => {
                return {
                value: item.id,
                // label: item.name[this.lang],
                label: this.isRTL ? item.name['ar'] : item.name['en']
                }
            })
        },
        plans(){
            return this.plansRows.map(item => {
                return {
                    value: item.id,
                    label: item.name[this.language],
                }
            })
        },
        guides(){
            return this.guidesRows.map(item => {
                return {
                    value: item.id,
                    label: item.fullName,
                }
            })
        }
    },
    methods:{
        ...mapActions({
            doFetchPlans: "plan/list/doFetchAll",
            doFetchGuides: "tourGuide/list/doFetch",
            finishLoadingTouguides: "tourGuide/list/finishLoading",
            doCreate: 'trip/form/doCreate',
            doFetchCities: 'city/list/doFetch',

        }),
        i18n(key, args) {
            return this.$t(key, args);
        },
        filterFn (val, update) {
            if (val === '') {
                update(() => {
                this.plansOptions = this.plans
                })
                return
            }
            update(() => {
                const needle = val.toLowerCase()
                this.plansOptions = this.plans.filter(v => v.label.toLowerCase().indexOf(needle) > -1)
                
            })
        },
        filterFnGuides (val, update) {
            if (val === '') {
                update(() => {
                    this.guidesOptions = this.guides
                })
                return
            }
            update(() => {
                const needle = val.toLowerCase()
                this.guidesOptions = this.guides.filter(v => v.fullName.toLowerCase().indexOf(needle) > -1)                
            })
        },
        filterFnCities (val, update) {
            if (val === '') {
                update(() => {
                this.citiesOptions = this.cities
                })
                return
            }

            update(() => {
                const needle = val.toLowerCase()
                this.citiesOptions = this.cities.filter(v => v.label.toLowerCase().indexOf(needle) > -1)
            })
        },
        inputDate(){
            this.tripDate = this.data.start_date
            this.$refs.qDateProxy.hide()
        },
        inputTime(){
            this.tripTime =  this.currentTime
            this.$refs.qTimeProxy.hide()
        },
        convertDateToTimestamp(dateString){
            var dateTimeParts = dateString.split(' '),
            timeParts = dateTimeParts[1].split(':'),
            dateParts = dateTimeParts[0].split('-'),
            date;
            date = new Date(dateParts[2], parseInt(dateParts[1], 10) - 1, dateParts[0], timeParts[0], timeParts[1]);
            return date
        },


        closeLocation(){
            console.log(this.model.location);            
            this.locationModal = false
        },
        async doSubmit(){
            this.model.planId = this.planId
            this.model.guidesId = this.guidesId
            const minCapacity = parseInt(this.model.capacity.min)
            const maxCapacity = parseInt(this.model.capacity.max)
            const pricePrivate = parseFloat(this.model.price.private)
            const priceGroup = parseFloat(this.model.price.group)
            const priceExtra = parseFloat(this.model.price.extra)
            const optionsPrice = parseFloat(this.model.options.price)
            this.model.options.price = optionsPrice
            this.model.capacity.min = minCapacity
            this.model.capacity.max = maxCapacity
            this.model.price.private = pricePrivate
            this.model.price.group = priceGroup
            this.model.price.extra = priceExtra
            this.model.captainId = this.tripCaptainId
            this.model.tripTime = this.convertDateToTimestamp(this.tripDate + ' ' + this.tripTime)
            console.log('model',this.model);   
            debugger         
            await this.doCreate(this.model)
        },
        updateOptionsDetails(index){
            console.log('this.model.options', this.model.options);
            this.model.options.details[index] = {
                en: this.detailsEn
            }
            console.log(this.model);
        },
        onMarkerDrag(e) {
            console.log(e.sourceTarget._latlng);
            this.model.location = {
                latitude: e.sourceTarget._latlng.lat,
                longitude: e.sourceTarget._latlng.lng
            }
            // debugger
            // popup
            //     .setLatLng(e.latlng)
            //     .setContent("You clicked the map at " + e.latlng.toString())
            //     .openOn(map);
        },
       
    },
    watch:{
        searchControl(newval){
            console.log(newval);
        }
    },
    async mounted(){
        console.log('here');
        await this.doFetchPlans()
        const pagination = {
            sortBy: "desc",
        }
        this.doFetchGuides({companyId: this.currentUser.companyId, orderBy: 'createdAt', pagination})
        this.finishLoadingTouguides()
        this.doFetchCities()

    },
    async created(){
        this.model = formSchema.initialValues(this.record);
        // this.model.type = fields.type.options[1].value;

        // this.model.type = this.fields.type.options[1].value;
        this.model.status = 'scheduled'
        this.model.name = this.model.name || { en: undefined, ar: undefined }
        this.model.capacity = this.model.capacity || { min: undefined, max: undefined } 
        this.model.price = this.model.price || { private: undefined, group: undefined, extra: undefined }
        this.model.tripTime = this.model.tripTime || moment(Date.now()).format('DD-MM-YYYY')
        this.model.location = this.model.location || { latitude: 21.485811, longitude: 39.19250479999999 } 
        this.model.options = this.model.options || { 
            price: undefined, 
            details: [{ en: undefined, ar: undefined },{},{},{},{},{}] 
        }
        this.model.ownerType = 'company'
        this.model.companyId = this.currentUser.companyId

    }
}
