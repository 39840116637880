import { i18n } from '@/i18n';
import IdField from '@/shared/fields/id-field';
import DateTimeRangeField from '@/shared/fields/date-time-range-field';
import DateTimeField from '@/shared/fields/date-time-field';
import { GenericModel } from '@/shared/model/generic-model';
import StringField from '@/shared/fields/string-field';
// import IntegerField from '@/shared/fields/integer-field';
import DecimalField from '@/shared/fields/decimal-field';
import EnumeratorField from '@/shared/fields/enumerator-field';
import StringArrayField from '@/shared/fields/string-array-field';
// import BooleanField from '@/shared/fields/boolean-field';
import JsonField from '@/shared/fields/json-field';
// import IntegerRangeField from '@/shared/fields/integer-range-field';
// import ImagesField from '@/shared/fields/images-field';
// import i18n from './vueI18n'

function label(name) {
  return i18n(`entities.trip.fields.${name}`);
}
function enumeratorLabel(name, value) {
  return i18n(`entities.trip.enumerators.${name}.${value}`);
}

const fields = {
  id: new IdField('id', label('id')),
  companyId: new IdField('companyId', label('companyId')),
  name: new JsonField('name', label('name')),
  // description: new JsonField('description', label('description')),
  startCityId: new StringField('startCityId', label('startCityId'), {}),
  // startCity: new StringField('startCity', label('startCity'), {}),
  planId: new StringField('planId', label('planId'), {}), 
  plan: new StringField('plan', label('plan'), {}),
  guidesId: new StringArrayField('guidesId', label('guidesId'), {}),
  guides: new StringField('guides', label('guides'), {}),
  capacity: new JsonField('capacity', label('capacity')),
  price: new JsonField('price', label('price')),
  tripTime: new DateTimeField('tripTime', label('tripTime')),
  location: new JsonField('location', label('location')),
  options: new JsonField('options', label('options')),  
  type: new EnumeratorField('type', label('type'), [
    { value: 'default', label: enumeratorLabel('tripType', 'default') },
    { value: 'custom', label: enumeratorLabel('tripType', 'custom') },
  ]),
  paymentMethod: new EnumeratorField('paymentMethod', label('paymentMethod'), [
    { value: 'credit', label: enumeratorLabel('tripPaymentMethod', 'credit') },
    { value: 'cash', label: enumeratorLabel('tripPaymentMethod', 'cash') },
    { value: 'both', label: enumeratorLabel('tripPaymentMethod', 'both') },

  ]), 

  
  ownerType: new EnumeratorField('ownerType', label('ownerType'), [
    { value: 'company', label: enumeratorLabel('tripType', 'company') },
    { value: 'guide', label: enumeratorLabel('tripType', 'guide') },
    { value: 'client', label: enumeratorLabel('tripType', 'client') },
  ]),

  status: new EnumeratorField('status', label('status'), [
    { value: 'pending', label: enumeratorLabel('tripStatus', 'pending') },
    { value: 'scheduled', label: enumeratorLabel('tripStatus', 'scheduled') },
    { value: 'started', label: enumeratorLabel('tripStatus', 'started') },
    { value: 'done', label: enumeratorLabel('tripStatus', 'done') },
    { value: 'cancelled', label: enumeratorLabel('tripStatus', 'cancelled') },
    { value: 'history', label: enumeratorLabel('tripStatus', 'history') },
  ]),

  rate: new DecimalField('rate', label('rate')),  


  // numberOfDays: new EnumeratorField('numberOfDays', label('numberOfDays'), [
  //   { value: 2, label: i18n('entities.trip.tripDays.twoDays') },
  //   { value: 3, label: i18n('entities.trip.tripDays.ThreeDays') },
  //   { value: 4, label: i18n('entities.trip.tripDays.FourDays') },
  //   { value: 5, label: i18n('entities.trip.tripDays.FiveDays') },
  //   { value: 6, label: i18n('entities.trip.tripDays.SixDays') },
  //   { value: 7, label: i18n('entities.trip.tripDays.SevenDays') },
  // ]),
  // disabled: new BooleanField('disabled', label('disabled'), {
  //   noLabel: i18n('user.enabled'),
  //   yesLabel: i18n('user.disabled'),
  // }),
  // programs: new StringArrayField('programs', label('programs'), {}),


  // //#region []
  //   numberOfTrips: new IntegerField('numberOfTrips', label('numberOfTrips'), {}),
  //   cityName: new StringArrayField('cityName', label('cityName'), {}),
  // //#endregion

  createdAt: new DateTimeField('createdAt', label('createdAt')),
  updatedAt: new DateTimeField('updatedAt', label('updatedAt')),
  createdAtRange: new DateTimeRangeField('createdAtRange', label('createdAtRange')),
};

export class TripModel extends GenericModel {
  static get fields() {
    return fields;
  }
}